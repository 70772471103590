<template>
    <div>
        <h2>สต็อก</h2>
        <v-card class="mt-4 px-6 pt-6">
            <v-row>              
              <v-col cols="12" md="4">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips 
                  cache-items
                  v-model="datatable.warehouse_id"             
                  label="คลัง"
                  placeholder="พิมพ์เพื่อค้นหาคลัง"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/inventory/warehouseList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="warehouseAutocomplete"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                ></delay-autocomplete>
              </v-col>                  
              <v-col cols="12" md="4">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips 
                  cache-items
                  v-model="datatable.brand_id"             
                  label="แบรนด์"
                  placeholder="พิมพ์เพื่อค้นหาแบรนด์"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/inventory/brandList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="brandAutocomplete"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                ></delay-autocomplete>
              </v-col>    
              <v-col cols="12" md="4">
                <v-checkbox
                  label="ซ่อนที่สต็อคเป็น 0"
                  v-model="datatable.hide_zero"
                  class="mt-0"
                ></v-checkbox>
              </v-col>                        
            </v-row>
            <v-row>
              <v-col
              cols="12" md="8"
              >
                <v-text-field
                  outlined
                  v-model="datatable.search"
                  placeholder="ค้นหา ..."
                  dense
                  @keydown.enter="search"
                ></v-text-field>
              </v-col>
              <v-col
              cols="12" md="4"
              >
                <v-btn
                @click="search"
                color="primary"
                >ค้นหา</v-btn>
              </v-col>
            </v-row>            
        </v-card>
        <v-card class="mt-4">
          <div class="d-flex pa-4">
            <v-btn  
            class="ml-2"   
            @click="$router.push({ 'name': 'stock_export' })"            
            ><v-icon class="mr-2">{{ mdiExportVariant }}</v-icon>ส่งออก</v-btn>
            <v-btn  
            class="ml-2"   
            @click="$router.push({ 'name': 'stock_import' })"
            v-if="$store.getters['auth/GET_ABILITY'].can('update', 'Inventory')"
            ><v-icon class="mr-2">{{ mdiImport }}</v-icon>นำเข้า</v-btn>            
          </div>
          <delay-datatable
            :headers="datatable.headers"
            :options.sync="datatable.options"  
            :url="'/inventory/list'"            
            :queryParams="getDatatableQueryParams"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
            :isMaualSearch="true"
          >
          
          <template          
            v-slot:item.images="{ item }"
          >   
            <product-image :image="item.images[0] ? item.images[0].URI : null" />                           
          </template>

          <template          
            v-slot:item.options="{ item }"
          >   
            <template v-for="option in item.options">
              {{ option }}
              <br>
            </template>
          </template>

          <template          
            v-slot:item.sku="{ item }"
          >   
            <template v-if="isArray(item.sku)">
              <template v-for="sku in item.sku">
                {{ sku }}
                <br>
              </template>
            </template>
            <template v-else>{{ item.sku }}</template>
          </template>

          <template          
            v-slot:item.barCode="{ item }"
          >   
            <template v-if="isArray(item.barCode)">
              <template v-for="barCode in item.barCode">
                {{ barCode }}
                <br>
              </template>
            </template>
            <template v-else>{{ item.barCode }}</template>
          </template>
          
          <template          
            v-slot:item.brand_name="{ item }"
          >   
            {{ !!item.brand_name ? item.brand_name : '-' }}
          </template>

          <template          
            v-slot:item.sum_available="{ item }"
          >   
            {{ item.sum_quantity - item.sum_reserved }}
          </template>

          <!--
          <template          
            v-slot:item.sum_quantity="{ item }"
          >   
            {{ reduceInventory(item.inventory) }}
          </template>

          <template          
            v-slot:item.sum_reserved="{ item }"
          >   
            {{ reduceReserved(item.inventory) }}
          </template>

          <template          
            v-slot:item.sum_available="{ item }"
          >   
            {{ sumAvailable(item.inventory) }}
          </template>
          -->
          
          <template          
            v-slot:item.actions="{ item }"
          >   
            <v-btn
              icon
              @click="$router.push({name: 'stock_view', params: { product_id: item.id }, query: { warehouses: converters.jsonToBase64(datatable.warehouse_id) }})"
              v-if="item.product_type == 'simple'"
            ><v-icon>{{ mdiEyeOutline }}</v-icon></v-btn>            
            <v-btn
              icon
              @click="$router.push({name: 'stock_view_variation', params: { product_id: item.id }, query: { warehouses: converters.jsonToBase64(datatable.warehouse_id) }})"
              v-else
            ><v-icon>{{ mdiFormatListText }}</v-icon></v-btn>            
          </template>
          </delay-datatable>
        </v-card>        
    </div>
</template>
<script>
import ProductImage from '@/components/ProductImage.vue'
import { jsonToBase64 } from '@/helpers/converter.js'
import { mdiExportVariant, mdiEyeOutline, mdiFormatListText, mdiImport } from '@mdi/js'
export default {
  data() {
    return {
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        selectedItems: [],
        brand_id: null,
        warehouse_id: null,
        search: null,
        hide_zero: false,
        headers: [
          {
            text: 'รูป',
            value: 'images',
            sortable: false,
          },
          {
            text: 'ชื่อสินค้า',
            value: 'name',
          },
          {
            text: 'ตัวเลือกสินค้า',
            value: 'options',
          },
          {
            text: 'sku',
            value: 'sku',
          },
          {
            text: 'บาร์โคด',
            value: 'barCode',
          },
          {
            text: 'แบรนด์',
            value: 'brand_name',
          },
          {
            text: 'จำนวน',
            value: 'sum_quantity',
          },
          {
            text: 'ติดจอง',
            value: 'sum_reserved',
          },
          {
            text: 'ขายได้',
            value: 'sum_available',
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            align: 'center',
          },
        ],
      },
      mdiEyeOutline,
      mdiExportVariant,
      mdiImport,
      mdiFormatListText,
      converters: {
        jsonToBase64,
      },
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        brand_id: this.datatable.brand_id,
        warehouse_id: this.datatable.warehouse_id,
        hide_zero: this.datatable.hide_zero,
        search: this.datatable.search,
      }
    },
  },
  methods: {
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }

      /*
      const that = this
      this.$nextTick().then(() => {
        that.search()
      })
      */
    },
    isArray(data) {
      return Array.isArray(data)
    },
    reduceInventory(inventory) {
      if (!!!inventory || inventory.length == 0) return 0

      return inventory
        .map(data => data.quantity)
        .reduce((total, currentQuantity) => {
          return total + currentQuantity
        })
    },
    reduceReserved(inventory) {
      if (!!!inventory || inventory.length == 0) return 0

      return inventory
        .map(data => data.reserved)
        .reduce((total, currentReserved) => {
          return total + currentReserved
        })
    },
    sumAvailable(inventory) {
      if (!!!inventory || inventory.length == 0) return 0

      const currentQuantity = this.reduceInventory(inventory)
      const currentReserved = this.reduceReserved(inventory)

      return currentQuantity - currentReserved
    },
    search() {
      this.$refs.mainDatatable.reload()
    },
  },
  components: {
    ProductImage,
  },
}
</script>